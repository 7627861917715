import React from 'react'
import ProductTemplate from 'components/templates/ProductTemplate/ProductTemplate'

export default function GeneralSpecificPartWorkerTrainingPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const products = {
    0: {
      productId: 'GSPWT',
      productName: 'Formazione lavoratore parte generale + specifica',
      productImages: {
        0: [
          'courses/corso-formazione-lavoratore-parte-generale-+-specifica.jpg',
          'corso formazione lavoratore parte generale + specifica',
        ],
      },
      productSize: 'Un corso formazione',
      productWeight: 0,
      productPrice: '5500',
      productPriceDesc: '(invece di 80,00 € = 40,00 + 40,00 €)',
      price: 'price_1J0o7YLde8wm4I0ogAGAC5Hy',
    },
  }

  const description =
    '<strong>OBBLIGATORIETÀ:</strong> Obbligatorio per tutti i lavoratori di qualsiasi tipologia di azienda del settore a rischio basso delle macrocategorie e corrispondenza ATECO 2002-2007. Il corso corrisponde alla somma di PARTE GENERALE (4 H) e PARTE SPECIFICA (4 H) e copre totalmente l’obbligo di formazione del lavoratore. È rivolto anche alle aziende che all’interno del proprio organico hanno diversi tipi di rischio (alto, medio, basso): gli allievi possono partecipare al presente corso (4 h+4 h) e poi seguire separatamente l’ulteriore parte specifica a seconda del rischio di appartenenza. Offre il vantaggio del costo totale inferiore alla somma dei due corsi.<br/><br/> <strong>DURATA:</strong> 8 h<br/>'

  const presentation = {
    title: 'Formazione lavoratore parte generale + specifica',
    description:
      '<strong>COME SI SVOLGE:</strong> ll corso si svolge online in modalità e-learning. All’acquisto vengono inviati link e credenziali con i quali collegarsi in qualsiasi momento, anche in più volte, dal proprio PC o dallo smart phone. Alla fine del corso è previsto un test finale che può essere sostenuto più volte, superato il quale viene rilasciato regolare attestato di avvenuta formazione, accreditato da Regione, in conformità al D. Lgs. 81/08 ed all’Accordo Stato Regioni del 21 dicembre 2011, che definisce durata e contenuti minimi del corso.<br/><br/> <strong>DESCRIZIONE:</strong> Come previsto dall’Accordo Stato Regioni del 21.12.2011, il corso fornisce ai partecipanti la parte generale e gli approfondimenti e aggiornamenti necessari per conoscere i principi del Sistema di Prevenzione e Protezione adottato presso le Aziende e attraverso quali strumenti e strategie il lavoratore è chiamato ad operare per preservare lo stato di sicurezza sul luogo di lavoro.',
    photo: 'corso-formazione-generale-diagramma.jpg',
  }

  return (
    <>
      <ProductTemplate
        seoKeywords={seoKeywords}
        products={products}
        description={description}
        presentation={presentation}
      />
    </>
  )
}
